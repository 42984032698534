// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  urlTeso: 'https://apiventanilla.colegiodenotariosedomex.org.mx/api/v2/notarios',
  apiKey: 'notarios_vIqrG48x3wg86CcA82KKF611rQGk4ZYoYQaJPOSu1TloIp8m27d04Fm',
  cryptoKey: "notarios_crypto_so2nqec75ps3fwVCtQOFkUWE735rBSUqU7yCaDvkRjJpsQlT",
  firebase: {
    apiKey: "AIzaSyBVDS_qf6mprjIoqh6ZI-zKZvHLcQeUI6g",
    authDomain: "validados-ven-prod.firebaseapp.com",
    projectId: "validados-ven-prod",
    storageBucket: "validados-ven-prod.appspot.com",
    messagingSenderId: "565720914522",
    appId: "1:565720914522:web:e467f24a6d683f71b6d663",
    measurementId: "G-VQ4GEY72ZX"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
